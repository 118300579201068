import React, { useContext, useEffect, useState } from "react";

import { PinMapContext } from "./PinMapProvider";

import Introduction from "./Introduction";
import Header from "../Layout/Header";
import Menu from "../Layout/Menu";
import Map from "./Map";
import CreatePin from "./CreatePin";
import ShowPin from "./ShowPin";
import Footer from "../Layout/Footer";
import InfoBox from "./InfoBox";

import FeaturedPinPage from "./Pages/FeaturedPin";
import JoinPage from "./Pages/Join";
import AboutPage from "./Pages/About";

import { getPins, getAnnotationCategories } from "./utils/api";

const PinMapInterface = () => {
  const {
    page,
    showIntro,
    mapLanguage,
    setPinData,
    setPinAnnotationCategories,
    newPinData,
    openPin,
    showInfoBox,
  } = useContext(PinMapContext);

  useEffect(() => {
    getPins().then((pins) => {
      setPinData(pins);
    });

    getAnnotationCategories().then((annotationCategories) => {
      setPinAnnotationCategories(annotationCategories);
    });
  }, []);

  return (
    <div
      id="pin-map-interface"
      data-language={mapLanguage}
      className={newPinData || openPin ? "sidebar-open" : ""}
    >
      <Header />
      <Menu />
      {page === "map" && (
        <>
          {showIntro && <Introduction />}
          {!showIntro && <Map />}
          {newPinData && <CreatePin />}
          {openPin && <ShowPin />}
          {showInfoBox && <InfoBox />}
        </>
      )}
      {page === "featured" && <FeaturedPinPage />}
      {page === "join" && <JoinPage />}
      {page === "about" && <AboutPage />}
      <Footer />
    </div>
  );
};

export default PinMapInterface;
