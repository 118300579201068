import React, { useContext, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import Reactions from "./Reactions";
import { PinMapContext } from "./PinMapProvider";
import { getPinImageUrl } from "./utils/api";

const ShowPin = () => {
  const { plausible, openPin, setOpenPin, setOpenPinFromFeatured, mapHeight, isMobile } =
    useContext(PinMapContext);
  const { t } = useTranslation();

  const [pinData, setPinData] = useState(null);
  const [pinHeadline, setPinHeadline] = useState(t("showPin.title"));

  const [visible, setVisible] = useState(false);

  const [reactionsDone, setReactionsDone] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 5);
  }, []);

  useEffect(() => {
    if (!openPin) return;
    setPinData(null);
    setReactionsDone([]);

    const fetchPinData = async () => {
      let imageUrl = openPin.properties.image_url;

      if (imageUrl) {
        const res = await getPinImageUrl(openPin.properties.id);
        imageUrl = res.url;
      }

      setPinData({
        ...openPin.properties,
        image_url: imageUrl,
      });
    };

    fetchPinData();
  }, [openPin]);

  useEffect(() => {
    if (!pinData) return;

    const nameAnnotation = pinData.annotation_values.find(
      (a) => ["Give this place a name ", "Give this place a name"].includes(a.annotation_category.name)
    );

    if (nameAnnotation) {
      setPinHeadline(nameAnnotation.value);
    }
  }, [pinData]);

  const handleReactionCallback = (newPinData) => {
    setPinData(newPinData);
  }

  const handleClickCloseSidebar = () => {
    setOpenPin(null);
    setOpenPinFromFeatured(null);
  }

  if (!pinData) return;

  let sidebarClassList = "map-sidebar";
  if (visible) sidebarClassList += " active";
  sidebarClassList += isMobile ? " mobile" : " desktop";

  let sidebarHeight = isMobile ? mapHeight - 150 : mapHeight;

  return (
    <div
      id="show-pin-container"
      className={sidebarClassList}
      style={{ height: sidebarHeight + "px" }}
    >
      <div
        id="close-show-pin"
        className="close-sidebar"
        onClick={handleClickCloseSidebar}
      >
        ×
      </div>

      <div id="show-pin-content" className="sidebar-content">
        {pinData.status === "pending" && (
          <p className="show-pin-status w-85 c-white bg-red border-radius p-1 mb-1">{t("showPin.pending")}</p>
        )}
        <h1 className="show-pin-headline international-font">{pinHeadline}</h1>

        {pinData.annotation_values?.map((annotation) => {
          if (annotation.annotation_category.annotation_type === "textarea") {
            return (
              <p
                className="show-pin-description international-font"
                key={annotation.value}
              >
                {annotation.value}
              </p>
            );
          }
        })}

        {pinData.image_url && (
          <div className="show-pin-image-container">
            <img className="pin-image" src={pinData.image_url} />
          </div>
        )}

        {!pinData.image_url && <img src=""></img>}

        <div id="pin-reaction-container">
          <Reactions plausible={plausible} pinData={pinData}  handleCallback={handleReactionCallback} />
        </div>
      </div>
    </div>
  );
};

export default ShowPin;
