import React, { useContext, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import { PinMapContext } from "../PinMap/PinMapProvider";

import LanguageSelector from "../PinMap/LanguageSelector";
import questionMarkIcon from "./../../../images/question-icon.svg";

const Footer = () => {
  const { mapLanguage, setShowInfoBox } = useContext(PinMapContext);
  const { t } = useTranslation();

  return (
    <footer>
      <div
        id="footer-content"
        className="flex justify-between align-items-center"
      >
        <LanguageSelector />

        {/* <div
          id="info-box-toggle"
          className="cursor-pointer flex"
          onClick={() => setShowInfoBox(true)}
        >
          <img
            src={questionMarkIcon}
            className={mapLanguage === "ar" ? "flip" : ""}
            style={{ width: "20px", height: "20px", display: "block" }}
            alt=""
          />
        </div> */}
      </div>
    </footer>
  );
};

export default Footer;
