import React from "react";

const Header = () => {
  return (
    <header>
      <div id="header-content" className="flex justify-between">
        <a className="ltr" href="/">
          Celebrate Madinah
        </a>
        <a className="rtl" href="/">
          لنحتفل معًا بجمال مدينتنا
        </a>
      </div>
    </header>
  );
};

export default Header;
