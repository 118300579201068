import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { PinMapContext } from "../PinMap/PinMapProvider";

const Menu = () => {
  const { page, setPage } = useContext(PinMapContext);
  const { t } = useTranslation();

  return (
    <nav className="flex justify-center flex-column">
      <div id="nav-content">
        <a href="/" className={page === "map" ? "selected" : ""}>
          {t("navigation.map")}
        </a>
        <a href="/featured" className={page === "featured" ? "selected" : ""}>
          {t("navigation.featured")}
        </a>
        <a href="/join" className={page === "join" ? "selected" : ""}>
          {t("navigation.join")}
        </a>
        <a href="/about" className={page === "about" ? "selected" : ""}>
          {t("navigation.about")}
        </a>
      </div>
    </nav>
  );
};

export default Menu;
