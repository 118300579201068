import React, { useContext, useState } from "react";

import languageIcon from "./../../../images/language-icon.svg";

import { PinMapContext } from "./PinMapProvider";

const LanguageSelector = () => {
  const { mapLanguage, setMapLanguage } = useContext(PinMapContext);

  const [showLanguageChoice, setShowLanguageChoice] = useState(false);

  const handleClickLanguage = (language) => {
    setMapLanguage(language);
    setShowLanguageChoice(false);
  }

  return (
    <div id="language-selector">
      <div
        id="language-toggle"
        className="cursor-pointer"
        onClick={() =>
          setShowLanguageChoice((showLanguageChoice) => !showLanguageChoice)
        }
      >
        <img
          src={languageIcon}
          style={{ width: "20px", height: "20px" }}
          alt=""
        />
        <span></span>
      </div>
      {showLanguageChoice && (
        <div
          id="language-choice-container"
          className="z-100"
        >
          <button
            id="button-en"
            className="block btn w-100"
            onClick={() => handleClickLanguage("en")}
          >
            <span className={mapLanguage === "en" ? "bold" : ""}>
              English
            </span>
          </button>
          <button
            id="button-ar"
            className="block btn w-100 rtl"
            onClick={() => handleClickLanguage("ar")}
          >
            <span className={mapLanguage === "ar" ? "bold" : ""}>
              العربية
            </span>
          </button>
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;
