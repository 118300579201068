import benchIcon from '../../../../images/icons/icon_bench.png';
import foodtruckIcon from '../../../../images/icons/icon_foodtruck.png';
import mosqueIcon from '../../../../images/icons/icon_mosque.png';
import palmsIcon from '../../../../images/icons/icon_palms.png';
import playIcon from '../../../../images/icons/icon_play.png';
import mobilityIcon from '../../../../images/icons/icon_mobility.png';
import picnicIcon from '../../../../images/icons/icon_picnic.png';
import peopleIcon from '../../../../images/icons/icon_people.png';
import questionmarkIcon from '../../../../images/icons/icon_questionmark.png';

import heartReaction from '../../../../images/reaction_heart.svg';
import heartReactionActive from '../../../../images/reaction_heart_active.svg';
import confettiReaction from '../../../../images/reaction_confetti.svg';
import confettiReactionActive from '../../../../images/reaction_confetti_active.svg';
import palmReaction from '../../../../images/reaction_palm.svg';
import palmReactionActive from '../../../../images/reaction_palm_active.svg';

export const multipleChoiceOptions = [
  'runErrands',
  'socializeConnectWithOthers',
  'exercisePlayMove',
  'pauseReflectRelax',
  'enjoyWithNature',
  'goToCafeRestaurant',
  'picnicEveningHangout',
  'goForInspirationFun',
  'praySpiritualPractice',
];

export const iconOptions = [
  { name: "bench", src: benchIcon },
  { name: "foodtruck", src: foodtruckIcon },
  { name: "mosque", src: mosqueIcon },
  { name: "palm", src: palmsIcon },
  { name: "play", src: playIcon },
  { name: "mobility", src: mobilityIcon },
  { name: "picnic", src: picnicIcon },
  { name: "people", src: peopleIcon },
];

export const placeholderIcon = { name: "placeholder", src: questionmarkIcon }

export const reactionOptions = [
  { emoji: "heart", icon: heartReaction, iconActive: heartReactionActive },
  { emoji: "confetti", icon: confettiReaction, iconActive: confettiReactionActive },
  { emoji: "palm", icon: palmReaction, iconActive: palmReactionActive },
]

export const generateRandomString = () => {
  const numberOfChars = 12;
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  let result = '';
  for (let i = 0; i < numberOfChars; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return result;
}

export const resizeImage = (file, maxWidth, maxHeight, callback) => {
  const reader = new FileReader();
  reader.onload = (e) => {
    const img = document.createElement('img');
    img.onload = () => {
      const canvas = document.createElement('canvas');
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }

      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob((blob) => {
        callback(blob);
      }, file.type, 1);
    };
    img.src = e.target.result;
  };
  reader.readAsDataURL(file);
}

export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }

  return array;
}

export const toTranslationKey = (path, text) => {
  let key = text.toLowerCase()
    .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()?\[\]@"'+<>\\|]/g, '') // Remove punctuation
    .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
    .trim();

  // Convert spaces to camelCase except for the first word
  const words = key.split(' ');
  key = words.map((word, index) => {
    // Keep the first word as is, capitalize the first letter of subsequent words
    return index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1);
  }).join('');

  return `${path}.${key}`;
}

export const convertImageToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(",")[1]); // Strip the base64 metadata
    reader.onerror = (error) => reject(error);
  });
};