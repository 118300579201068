import React, { useContext, useState } from "react";
import { useTranslation, Trans } from "react-i18next";

const AboutPage = () => {
  const { t } = useTranslation();

  return (
    <div
      id="about-page"
      className="pin-map-page-container simple-page-container bg-white"
    >
      <div className="pin-map-page-content py-2 px-1">
        <h1 className="show-pin-headline">{t("about.headline")}</h1>
        <p className="gray-text">
          <Trans
            i18nKey="about.description"
            components={{
              br: <br />,
              a: <a href="/pages/terms" />,
            }}
          />
        </p>

        <h1 className="show-pin-headline mt-2">{t("about.contactHeadline")}</h1>
        <p className="gray-text">{t("about.contactDescription")}</p>
        <p className="gray-text international-font">celebratemadinah@raseel.city</p>
      </div>
    </div>
  );
};

export default AboutPage;
