import React, { useContext, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import { PinMapContext } from "./PinMapProvider";

import nextIcon from "../../../images/icons/icon_next.svg";
import previousIcon from "../../../images/icons/icon_previous.svg";

import instructionIllustration01 from "../../../images/instructions/illustration_instructions01.svg";
import instructionIllustration02 from "../../../images/instructions/illustration_instructions02.svg";
import instructionIllustration03 from "../../../images/instructions/illustration_instructions03.svg";
import instructionIllustration04 from "../../../images/instructions/illustration_instructions04.svg";
import instructionIllustration05 from "../../../images/instructions/illustration_instructions05.svg";

const Instructions = () => {
  const { plausible, setShowInstructions } = useContext(PinMapContext);
  const { t } = useTranslation();

  const [instructionsIndex, setInstructionsIndex] = useState(0);

  const swipeStartX = useRef();

  const instructions = [
    {
      headline: t("instructions.headline0"),
      text: t("instructions.text0"),
      image: instructionIllustration01,
    },
    {
      headline: t("instructions.headline1"),
      text: t("instructions.text1"),
      image: instructionIllustration02,
    },
    {
      headline: t("instructions.headline2"),
      text: t("instructions.text2"),
      image: instructionIllustration03,
    },
    {
      headline: t("instructions.headline3"),
      text: t("instructions.text3"),
      image: instructionIllustration04,
    },
    {
      headline: t("instructions.headline4"),
      text: t("instructions.text4"),
      image: instructionIllustration05,
    },
  ];

  const handleNext = () => {
    if (instructionsIndex < instructions.length - 1) {
      setInstructionsIndex(instructionsIndex + 1);
    } else {
      setShowInstructions(false);
    }
  };

  const handleBack = () => {
    if (instructionsIndex > 0) {
      setInstructionsIndex(instructionsIndex - 1);
    }
  };

  const handleTouchStart = (e) => {
    swipeStartX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = (e) => {
    const swipeEndX = e.changedTouches[0].clientX;
    if (swipeStartX.current - swipeEndX > 100) {
      handleNext();
    } else if (swipeStartX.current - swipeEndX < -100) {
      handleBack();
    }
  };

  const handleDotClick = (index) => {
    setInstructionsIndex(index);
  };

  const handleClose = () => {
    plausible.trackEvent("closeInstructions");
    setShowInstructions(false);
  };

  const handleFinishInstructions = () => {
    plausible.trackEvent("finishInstructions");
    setShowInstructions(false);
  }

  return (
    <>
      <div id="disable-map-overlay" />
      <div id="instructions-container">
        <div
          id="instructions-content"
          style={{ padding: "1.5rem" }}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
        >
          <button className="close-button" onClick={handleClose}>
            &times;
          </button>

          <div className="instructions-content-wrapper">
            <div className="instructions-information">
              <div className="instruction-image">
                <img
                  src={instructions[instructionsIndex].image}
                  alt="Instruction Illustration"
                />
              </div>
              <div className="instruction-headline">
                {instructions[instructionsIndex].headline}
              </div>
              <p className="instruction-text">
                {instructions[instructionsIndex].text}
              </p>

              {instructionsIndex === instructions.length - 1 && (
                <button
                  className="btn mt-2"
                  onClick={handleFinishInstructions}
                >
                  {t("instructions.finishButtonText")}
                </button>
              )}
            </div>

            <div className="instructions-navigation">
              
              <div className="navigation-arrow" onClick={handleBack}>
                <img src={previousIcon} />
              </div>

              <div className="navigation-dots-container">
                {instructions.map((instruction, index) => {
                  return (
                    <div
                      key={index}
                      className={`navigation-dot ${
                        index === instructionsIndex ? "active" : ""
                      }`}
                      onClick={() => handleDotClick(index)}
                    ></div>
                  );
                })}
              </div>
              <div className="navigation-arrow" onClick={handleNext}>
                <img src={nextIcon} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Instructions;
