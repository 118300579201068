import React, { useContext, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import { PinMapContext } from "./PinMapProvider";

import titleImage from "./../../../images/logo.svg";
import gradientBlob from "./../../../images/gradient-blob.svg";

const Introduction = () => {
  const { plausible, setShowIntro } = useContext(PinMapContext);
  const { t } = useTranslation();

  const handleClickEnter = () => {
    plausible.trackEvent('clickEnterMap');
    setShowIntro(false);
  };

  return (
    <div
      id="introduction-container"
      className="pin-map-page-container flex-page-container"
    >
      <div id="graphics-container" className="w-100 relative">
        <img src={gradientBlob} id="blob-image" className="w-100 absolute" />
        <img src={titleImage} id="title-image" className="w-100" />
      </div>

      <div id="text-container" className="w-100 p-1">
        <h1 className="w-85">{t("introduction.title")}</h1>
        <p className="mt-1">{t("introduction.text")}</p>
        <p className="mt-1">{t("introduction.text2")}</p>
        <div className="mt-2 mb-1" id="enter-map-btn-container">
          <button
            className="btn"
            onClick={handleClickEnter}
          >
            {t("introduction.enterButton")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Introduction;
